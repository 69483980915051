import styled, { css } from 'styled-components';

import Modal from '@core/UI/Modal';
import { Typography } from '@core/UI/Typography';
import { ShareIcon } from '@core/components/Share/Share.styles';
import colors from '@core/constants/colors';
import { BREAKPOINTS } from '@core/constants/styles';

import Card from '@features/media-bank/common/Card';

export const AlbumModal = styled(Modal)`
  border-radius: 12px;
  padding: ${({ theme: { isExpanded } }) => (isExpanded ? '0' : '24px')};
  top: 50%;
  transform: translateY(-50%);
  width: 100%;

  @media ${BREAKPOINTS.TABLET} {
    padding: 24px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    padding: 32px;
    width: ${({ theme: { isExpanded } }) => (isExpanded ? '100%' : '920px')};
  }

  ${Typography} {
    cursor: default;
  }

  & > svg:first-child {
    display: ${({ theme: { isExpanded } }) => (isExpanded ? 'none' : 'inline-block')};
  }
`;

export const Name = styled(Typography).attrs({
  type: 'h3',
  color: 'prussianBlue',
})`
  display: ${({ theme: { isExpanded } }) => (isExpanded ? 'none' : 'block')};
  margin: 0 24px 16px 0;

  @media ${BREAKPOINTS.TABLET} {
    margin-left: 8px;
    font-size: 28px;
    line-height: 36px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    margin-left: 2px;
  }
`;

export const Description = styled(Typography).attrs({
  type: 'p2',
  color: 'prussianBlue',
})`
  display: ${({ theme: { isExpanded } }) => (isExpanded ? 'none' : 'block')};
  margin-bottom: 16px;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Meta = styled.div`
  display: ${({ theme: { isExpanded } }) => (isExpanded ? 'none' : 'grid')};
  align-items: center;
  margin-bottom: 16px;
  grid-template-columns: max-content 1fr;
  gap: 24px;

  @media ${BREAKPOINTS.TABLET} {
    grid-template-columns: 1fr max-content max-content;
  }

  ${ShareIcon} {
    color: ${colors.riverBlue};

    &:hover {
      color: ${colors.riverBlue};
    }
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  gap: 8px;
  grid-row: 2 / 3;
  grid-column: span 2;

  @media ${BREAKPOINTS.TABLET} {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
`;

export const Tag = styled.div`
  padding: 7px;
  border: 1px solid ${colors.softGrey};
  border-radius: 4px;
`;

export const CarouselContainer = styled.div`
  display: grid;
  grid-template-rows: ${({ theme: { isExpanded } }) => (isExpanded ? '346px' : '220px 88px')};
  row-gap: 16px;
  align-items: center;
  height: 100%;

  @media ${BREAKPOINTS.TABLET} {
    grid-template-columns: 28px 1fr 28px;
    grid-template-rows: ${({ theme: { isExpanded } }) => (isExpanded ? '485px' : '440px 88px')};
    column-gap: 8px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    grid-template-rows: ${({ theme: { isExpanded } }) => (isExpanded ? 'calc(100vh - 240px)' : '440px 88px')};
  }

  .swiper {
    grid-column: 2 / 3;
    width: 100%;
    height: 100%;
  }

  .swiper-thumbs {
    display: ${({ theme: { isExpanded } }) => (isExpanded ? 'none' : 'block')};
    width: auto;
    height: auto;
  }

  .swiper-thumbs .swiper-slide {
    height: 88px;
    width: 64px;
    padding: 12px 0;
  }

  .swiper-thumbs .swiper-slide-thumb-active {
    height: 88px;
    width: 88px;
    padding: 0;
  }
`;

export const NavButton = styled.div`
  display: ${({ theme: { isExpanded } }) => (isExpanded ? 'block' : 'none')};
  padding: 0;
  background: transparent;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  width: 28px;
  height: 28px;

  path {
    fill: ${colors.white};
  }

  @media ${BREAKPOINTS.TABLET} {
    display: block;
    position: static;

    path {
      fill: ${colors.riverBlue};
    }
  }
`;

export const PrevButton = styled(NavButton)`
  left: 0;
`;

export const NextButton = styled(NavButton)`
  right: 0;
`;

export const Controls = styled.div`
  display: ${({ theme: { isExpanded } }) => (isExpanded ? 'flex' : 'none')};
  gap: 8px;
  position: absolute;
  z-index: 1;
  height: 32px;
  right: 32px;
  top: 16px;

  @media ${BREAKPOINTS.TABLET} {
    right: 12px;
    top: 24px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    display: flex;
    right: 24px;
    top: 24px;
  }
`;

export const ControlButtonStyles = css`
  background: rgba(255, 255, 255, 0.8);
  border: 0;
  outline: none;
  border-radius: 2px;
  padding: 4px;
  cursor: pointer;
`;

export const DownloadLink = styled.a`
  ${ControlButtonStyles}
`;

export const ExpandButton = styled.button`
  ${ControlButtonStyles}

  ${({ theme: { isExpanded } }) => isExpanded && css`
    background: ${colors.riverBlue};

    path {
      fill: ${colors.white};
    }
  `}
`;

export const SlideIndex = styled.div`
  display: ${({ theme: { isExpanded } }) => (isExpanded ? 'block' : 'none')};
  position: absolute;
  right: 32px;
  bottom: 16px;
  background: rgba(255, 255, 255, 0.8);
  padding: 4px;
  border-radius: 2px;

  @media ${BREAKPOINTS.TABLET} {
    right: 12px;
    bottom: 16px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    display: block;
    right: 24px;
    bottom: 24px;
  }
`;

export const PhotoCard = styled(Card)`
  &::before {
    background: none;
  }
`;

export const ThumbCard = styled(PhotoCard)`
  width: 100%;
  height: 100%;
`;
