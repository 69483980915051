import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import colors from '@core/constants/colors';
import { BREAKPOINTS } from '@core/constants/styles';

import Card from '@features/media-bank/common/Card';

export const SecondaryAlbumCard = styled(Card)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${BREAKPOINTS.TABLET} {
    padding: 20px 24px;
  }

  ${Typography} {
    cursor: default;
  }
`;

export const Count = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 4px;

  svg {
    margin-top: 1px;
  }
`;

export const Name = styled(Typography).attrs({
  type: 'p2',
  color: 'white',
  weight: 'bold',
  ellipsisMaxLines: 3,
})`
  @media ${BREAKPOINTS.DESKTOP_LG} {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Tags = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
  position: absolute;
  top: 16px;
  right: 16px;

  @media ${BREAKPOINTS.TABLET} {
    top: 20px;
    right: 32px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    top: 20px;
    right: 16px;
  }
`;

export const Tag = styled.div`
  display: inline-block;
  background: rgba(255, 255, 255, 0.6);
  padding: 4px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.riverBlue};
  cursor: default;
`;
