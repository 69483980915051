import { ForwardedRef, forwardRef } from 'react';

import { CameraIcon } from '@core/UI/Icons';
import { Typography } from '@core/UI/Typography';
import { formatDateShort } from '@core/utils/date';

import * as UI from './SecondaryAlbum.styles';
import { ISecondaryAlbumProps } from './types';

const SecondaryAlbum = forwardRef((props: ISecondaryAlbumProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { album, onClick, styles, withTags } = props;
  const { createdAt, name, materials, tags } = album;

  return (
    <UI.SecondaryAlbumCard
      backgroundImageId={materials[0]?.file.id}
      ref={ref}
      styles={styles}
      onClick={() => onClick?.(album)}
    >
      <div>
        <Typography
          color="white"
          type="p2"
        >
          {formatDateShort(createdAt)}
        </Typography>
        <UI.Count>
          <CameraIcon />
          <Typography
            color="aquaHaze"
            type="p2"
          >
            {`${materials.length} фото`}
          </Typography>
        </UI.Count>

        {withTags && (
          <UI.Tags>
            {tags.map((tag) => (
              <UI.Tag key={tag.id}>
                {tag.name}
              </UI.Tag>
            ))}
          </UI.Tags>
        )}
      </div>

      <UI.Name>
        {name}
      </UI.Name>
    </UI.SecondaryAlbumCard>
  );
});

export default SecondaryAlbum;
