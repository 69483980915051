import EmailIcon from '@core/assets/icons/email.svg';
import OdnoklassnikiIcon from '@core/assets/icons/odnoklassniki.svg';
import TelegramIcon from '@core/assets/icons/telegram.svg';
import VKIcon from '@core/assets/icons/vk.svg';
import { SocialLinkConfig } from '@core/components/Share/types';
import { IAlbum } from '@core/models/media';

export const buildAlbumLink = (album: IAlbum) => `${window.location.origin}${window.location.pathname}?albumId=${album.id}`;

export const getAlbumSocialLinks = (album: IAlbum): SocialLinkConfig[] => {
  const link = buildAlbumLink(album);
  const shortDescription = album.description.substring(0, 300);

  return [
    {
      id: 1,
      Icon: <TelegramIcon />,
      href: `https://t.me/share/url?url=${link}&text=${album.name}`,
    },
    {
      id: 2,
      Icon: <EmailIcon />,
      href: `mailto:?subject=${album.name}&body=${shortDescription}...%0A%0A${link}`,
    },
    {
      id: 3,
      Icon: <VKIcon />,
      href: `https://vk.com/share.php?url=${link}&title=${album.name}`,
    },
    {
      id: 4,
      Icon: <OdnoklassnikiIcon />,
      href: `https://connect.ok.ru/offer?url=${link}&title=${album.name}`,
    },
  ];
};
