import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as ISwiper } from 'swiper/types';

import { EModalNames } from '@core/UI/Modal/constants';
import { Typography } from '@core/UI/Typography';
import Share from '@core/components/Share';
import { BREAKPOINTS } from '@core/constants/styles';
import { useMedia } from '@core/hooks/useMedia';
import { formatDateShort } from '@core/utils/date';
import { getPublicFileUrl } from '@core/utils/downloadFile';

import ArrowLeftIcon from '../../assets/arrow_left.svg';
import ArrowRightIcon from '../../assets/arrow_right.svg';
import DownloadIcon from '../../assets/download.svg';
import ExpandIcon from '../../assets/expand.svg';

import * as UI from './AlbumModal.styles';
import { buildAlbumLink, getAlbumSocialLinks } from './socials';
import { IAlbumModalProps } from './types';

import 'swiper/css';
import 'swiper/css/thumbs';

const AlbumModal = (props: IAlbumModalProps) => {
  const { album, onClose } = props;
  const { name, createdAt, description, tags, materials } = album;

  const isDesktop = useMedia(BREAKPOINTS.DESKTOP_LG);

  const [swiper, setSwiper] = useState<ISwiper | null>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<ISwiper | null>(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const router = useRouter();

  useEffect(() => {
    // Проверяем готовность роута - Cancel Rendering Route [https://github.com/vercel/next.js/issues/37362]
    if (!router.isReady) {
      return;
    }

    router.replace(`?albumId=${album.id}`, undefined, { shallow: true });

    return () => {
      router.replace(router.pathname, undefined, { shallow: true });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [album.id]);

  return (
    <ThemeProvider theme={{ isExpanded }}>
      <UI.AlbumModal
        name={EModalNames.ALBUM}
        onClose={onClose}
      >
        <UI.Name>
          {name}
        </UI.Name>
        <UI.Description>
          {description}
        </UI.Description>

        <UI.Meta>
          <UI.TagsContainer>
            {tags.map(
              (tag) => (
                <UI.Tag key={tag.id}>
                  <Typography type="p2">
                    {tag.name}
                  </Typography>
                </UI.Tag>
              ),
            )}
          </UI.TagsContainer>

          <Typography
            color="riverBlue"
            type="p2"
          >
            {formatDateShort(createdAt)}
          </Typography>

          <Share
            link={buildAlbumLink(album)}
            socials={getAlbumSocialLinks(album)}
          />
        </UI.Meta>

        <UI.CarouselContainer>
          <UI.PrevButton
            onClick={() => swiper?.slidePrev()}
          >
            <ArrowLeftIcon />
          </UI.PrevButton>

          <Swiper
            modules={[Thumbs]}
            slidesPerView={1}
            spaceBetween={10}
            thumbs={{ swiper: thumbsSwiper }}
            onSlideChange={({ activeIndex }) => {
              setActiveSlideIndex(activeIndex);
            }}
            onSwiper={setSwiper}
          >
            {materials.map((photo) => (
              <SwiperSlide key={photo.id}>
                <UI.PhotoCard
                  backgroundImageId={photo.file.id}
                  onClick={() => {
                    if (!isDesktop) {
                      setIsExpanded(true);
                    }
                  }}
                >
                  <UI.Controls>
                    <UI.DownloadLink
                      href={getPublicFileUrl(photo.file.id)}
                    >
                      <DownloadIcon />
                    </UI.DownloadLink>
                    <UI.ExpandButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsExpanded((currentIsExpanded) => !currentIsExpanded);
                      }}
                    >
                      <ExpandIcon />
                    </UI.ExpandButton>
                  </UI.Controls>

                  <UI.SlideIndex>
                    <Typography type="p1">
                      {`${activeSlideIndex + 1} из ${materials.length}`}
                    </Typography>
                  </UI.SlideIndex>
                </UI.PhotoCard>
              </SwiperSlide>
            ))}
          </Swiper>

          <UI.NextButton
            onClick={() => swiper?.slideNext()}
          >
            <ArrowRightIcon />
          </UI.NextButton>

          <Swiper
            centeredSlides
            centeredSlidesBounds
            watchSlidesProgress
            modules={[Thumbs]}
            slidesPerView="auto"
            spaceBetween={16}
            onSwiper={setThumbsSwiper}
          >
            {materials.map((photo) => (
              <SwiperSlide key={photo.id}>
                <UI.ThumbCard
                  backgroundImageId={photo.file.id}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </UI.CarouselContainer>
      </UI.AlbumModal>
    </ThemeProvider>
  );
};

export default AlbumModal;
